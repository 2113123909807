import api from './api';

class EjecucionProcesoCalidadService {

  ejecutar(procesoCalidad, request) {
    return api.post(`/api/ejecutar_proceso_calidad/${procesoCalidad.id}`, request);
  }

  ejecutarAlpha2(procesoCalidad, request) {
    return api.post(`/api/ejecutar_proceso_calidad_alpha2/${procesoCalidad.id}`, request);
  }

  continuarEjecucion(ejecucion_id, request) {
    return api.post(`/api/continuar_ejecucion_proceso_calidad/${ejecucion_id}`, request);
  }

  deleteItem(procesoId, id) {
    return api.delete(`/api/procesos_calidad/${procesoId}/ejecuciones_proceso_calidad/${id}`)
  }

  getArchivo(params) {
    return api.get(`/api/descargar_proceso_calidad/${params.id}`, { responseType: 'blob' });
  }

  getItems(id, cadenaBusqueda) {
    cadenaBusqueda = cadenaBusqueda ? cadenaBusqueda : ''
    return api.get(`/api/procesos_calidad/${id}/ejecuciones_proceso_calidad${cadenaBusqueda}`);
  }

  getEjecucionesPendientes() {
    return api.get(`/api/ejecuciones_pendientes_proceso_calidad`);
  }
  
}

export default new EjecucionProcesoCalidadService();
