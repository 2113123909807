<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title style="float: left">Ejecuciones del proceso de Calidad {{ proceso.nombre }}</v-card-title>
      <CButton
        v-if="permiteEjecutar"
        color="primary"
        class="px-4"
        style="float: right"
        @click="ejecutar()"
        >Nueva Ejecución</CButton
      >

      <v-card-text>
        <div class="text-center" v-if="loadingTiposOperacion">
          <div class="spinner-border" role="status"></div>
        </div>
        <filters
          v-if="!loadingTiposOperacion"
          :campos="filterFields"
          :itemsKeys="{
            text: 'name',
            value: 'id',
          }"
          :eventName="'fetchItemsEvent'"
        ></filters>
        <div>
          <v-alert
            v-if="!permiteEjecutar"
            v-model="alert"
            border="left"
            close-text="Close Alert"
            type="info"
            dark
            dismissible
          >
            Se están ejecutando procesos.  Una vez finalicen estarán disponibles las opciones de ejecución.
          </v-alert>
        </div>
        <div class="text-center" v-if="loading">
          <div class="spinner-border" role="status"></div>
        </div>
        <CDataTable
          v-if="!loading"
          hover
          striped
          :items="items"
          :fields="fields"
          :items-per-page="50"
          :active-page="activePage"
          :pagination="{ doubleArrows: false, align: 'center' }"
          @page-change="pageChange"
        >
          <template #estado="data">
            <td>
              &nbsp;<span
                v-if="data.item.estado == 'Pendiente'"
                class="spinner-border spinner-border-sm"
                role="status"
                v-c-tooltip.hover.click="data.item.log?data.item.log:''"
                @click="verLog(data.item)"
              ></span>
              <CBadge
                v-if="data.item.estado != 'Pendiente'"
                :color="data.item.estado == 'Procesado' ? 'success' : 'danger'"
                v-c-tooltip.hover.click="data.item.log?data.item.log:''"
                @click="verLog(data.item)"
              >
                {{ data.item.estado }}
              </CBadge>
            </td>
          </template>

          <template #acciones="data">
            <td>
              <CLink
                v-if="data.item.estado == 'Procesado'"
                href="#"
                @click="descargar(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Descargar'"
              >
                <CIcon name="cil-file" />
              </CLink>

              <CLink
                v-if="data.item.estado == 'Error'"
                href="#"
                @click="continuarEjecucion(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Continuar proceso'"
              >
                *<CIcon name="cil-check" />
              </CLink>
              <CLink
                href="#"
                @click="preEliminar(data.item)"
                class="card-header-action btn-setting"
                v-c-tooltip.hover.click="'Eliminar'"
              >
                x
              </CLink>
            </td>
          </template>
        </CDataTable>
      </v-card-text>
    </v-card>
    <br /><br />

    <v-dialog
      v-model="dialogLog"
      width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Logs:</span>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="itemLogsHeader"
            :items="itemLogs"
            item-key="name"
            class="elevation-1"
            :items-per-page="-1"
            hide-default-footer
          >
            
          </v-data-table>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="dialogLog = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea eliminar el registro?
        </v-card-title>

        <v-card-text>
          {{deleteItem?deleteItem.id:''}} - {{deleteItem?deleteItem.created_at:''}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="dialog = false">
            Cancelar
          </v-btn>

          <v-btn color="gray darken-1" text @click="eliminar"> Eliminar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Filters from "@/components/filters";
import EjecucionProcesoCalidadService from "@/services/ejecucionProcesoCalidad.service";
import ArchivoService from "@/services/archivo.service";
import ListaService from "@/services/lista.service";
import { mapState } from "vuex";
import EventBus from "@/common/EventBus";

export default {
  name: "Users",
  data() {
    return {
      items: [],
      fields: [
        { key: "id", label: "Código" },
        { key: "created_at", label: "Fecha y Hora", _classes: "font-weight-bold" },
        { key: "estado", label: "Estado" },
        { key: "acciones", label: "Acciones" },
      ],
      filterFields: [
        { value: "id", label: "Código" },
      ],
      proceso: {
        nombre: undefined
      },
      activePage: 1,
      loading: false,
      loadingTiposOperacion: false,
      ejecucionesPendientes: undefined,
      archivosPendientes: undefined,
      permiteEjecutar: true,
      alert: false,
      message: "",
      dialogLog: false,
      itemLog: '',
      itemLogs: [],
      itemLogsHeader: [
        { 
          text: 'Log',
          value: 'item' 
        }
      ],
      dialog: false,
      deleteItem: undefined,
    };
  },
  components: {
    Filters,
  },
  watch: {
    "ejecucionesPendientes"(newVal) {
      if(this.ejecucionesPendientes != undefined && this.archivosPendientes != undefined) {
        if(!this.ejecucionesPendientes.length && !this.archivosPendientes.length) {
          this.permiteEjecutar = true
        } else {
          this.alert= true
        }
      }
    },
    "archivosPendientes"(newVal) {
       if(this.ejecucionesPendientes != undefined && this.archivosPendientes != undefined) {
        if(!this.ejecucionesPendientes.length && !this.archivosPendientes.length) {
          this.permiteEjecutar = true
        } else {
          this.alert= true
        }
      }
    },
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Definición de proceso de calidad"))
      this.$router.push("/dashboard");
    
    if (localStorage.getItem("datos_proceso_calidad")) {
      this.proceso = JSON.parse(localStorage.getItem("datos_proceso_calidad"));
      this.fetchItems("");
    }
    // this.fetchEjecucionesPendientesProcesosCalidad();
    // this.fetchArchivosPendientes();
    
    EventBus.on("fetchItemsEvent", (cadenaBusqueda) => {
      this.fetchItems(cadenaBusqueda);
    });

  },
  methods: {
    fetchItems(cadenaBusqueda) {
      this.loading = true;
      EjecucionProcesoCalidadService.getItems(this.proceso.id, cadenaBusqueda).then(
        (response) => {
          this.items = response.data;
          // this.items = this.items.map(function f(x) {
          //   return { ...x, layout_nombre: x.layout.nombre };
          // });
          console.log(response.data);
          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    fetchEjecucionesPendientesProcesosCalidad() {
      EjecucionProcesoCalidadService.getEjecucionesPendientes().then(
        (response) => {
          this.ejecucionesPendientes = response.data;
          console.log('ejecuciones en curso...')
          console.log(response.data);
        },
        (error) => { 
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    fetchArchivosPendientes() {
      ArchivoService.getArchivosPendientes().then(
        (response) => {
          this.archivosPendientes = response.data;
          console.log('arvhivos en curso...')
          console.log(response.data);
        },
        (error) => { 
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    descargar(item) {
      EjecucionProcesoCalidadService.getArchivo(item).then(
        (response) => {
          var blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          var fileName = `ejecucion_proceso_calidad_${item.id}.csv`;
          this.saveAs(blob, fileName);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    saveAs(blob, fileName) {
      var url = window.URL.createObjectURL(blob);

      var anchorElem = document.createElement("a");
      anchorElem.style = "display: none";
      anchorElem.href = url;
      anchorElem.download = fileName;

      document.body.appendChild(anchorElem);
      anchorElem.click();

      document.body.removeChild(anchorElem);

      // On Edge, revokeObjectURL should be called only after
      // a.click() has completed, atleast on EdgeHTML 15.15048
      setTimeout(function () {
        window.URL.revokeObjectURL(url);
      }, 1000);
    },
    ejecutar() {
      this.$router.push({ path: `ejecutar/` });
    },
    continuarEjecucion(item) {
      console.log(item)
      let ultima_iteracion = item.log.split('Iteración ')
      ultima_iteracion = ultima_iteracion[ultima_iteracion.length-1].charAt(0)
  
      let ultimo_paso = item.log.split('Paso ')
      ultimo_paso = ultimo_paso[ultimo_paso.length-1].split(', ')[0]

      let ultimo_ejecutado= {
        'ultima_iteracion': ultima_iteracion,
        'ultimo_paso': ultimo_paso
      }
      alert(`Se continuará el proceso posterior al paso ${ultimo_paso} de la iteración ${ultima_iteracion}`)
      this.items[this.items.indexOf(item)].estado = "Pendiente"
      console.log(ultimo_ejecutado)
 
      EjecucionProcesoCalidadService.continuarEjecucion(item.id, ultimo_ejecutado).then(
        (response) => {
          console.log(response.data);
        },
        (error) => {
          this.content =
            ( error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    verLog(item){
      this.dialogLog = true
      this.itemLog = item.log
      this.itemLogs = this.itemLog.split('. ')
      this.itemLogs = this.itemLogs.map(function(item) {
                        return {
                          item: item
                        }
                      });
    },
    preEliminar(item) {
      this.deleteItem = item;
      this.dialog = true;
    },
    eliminar() {
      this.loading = true;
      this.dialog = false;
      EjecucionProcesoCalidadService.deleteItem(this.proceso.id, this.deleteItem.id).then(
          (response) => {
            console.log(response.data);
            this.fetchItems("");
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
};
</script>
